/**
 * The purchase course pages.
 */
import { useState } from 'preact/hooks';
import { router } from '@components/router';
import * as urls from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import {
  CourseCheckoutErrorPage,
  CourseCheckoutPage,
  finalCheckoutPageURL,
} from '@components/course-checkout';
import * as fmt from 'shared/payments/fmt';
import { useIntl } from 'shared/intl/use-intl';
import * as pmtmath from 'shared/payments/math';
import { AuthForm, PurchaseForm } from '@components/checkout';
import { setUrlSearchParam } from 'client/utils/url';
import { GiftForm, useGiftState } from './gift-form';
import * as terminology from 'shared/terminology';
import { ErrorPage } from '@components/error-page';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { PurchaseFn } from 'shared/payments/types';
import { isNullish } from 'shared/utils';

async function load(route: AppRoute) {
  const { courseId, priceId, couponId } = route.params;
  const result = await rpx.courseCheckout.getCourseCheckoutState({ courseId, priceId, couponId });
  return result;
}

type Props = LoadedProps<typeof load>;
type Data = Props['data'];

function RepurchasingForm({
  course,
  onAllowRepurchase,
}: {
  course: Data['course'];
  onAllowRepurchase(): void;
}) {
  const tenant = useCurrentTenant();
  const intl = useIntl();
  const courseLabel = terminology.courseLabel({
    course,
    intl,
    tenant,
  });
  return (
    <div class="flex flex-col justify-center gap-2">
      <p class="text-gray-500">
        {intl("Important: You're already enrolled in this {courseLabel:string}.", {
          courseLabel,
        })}
      </p>
      <BtnPrimary class="my-2" onClick={onAllowRepurchase}>
        {intl('Continue with signup')}
      </BtnPrimary>
      <BtnSecondary
        href={finalCheckoutPageURL({
          id: course.id,
          title: course.title,
        })}
      >
        {intl('Go to {courseLabel:string}', {
          courseLabel,
        })}
      </BtnSecondary>
    </div>
  );
}

function removeNullProps<T extends Record<string, any>>(obj: T) {
  const result = { ...obj };
  for (const k in result) {
    if (isNullish(result[k])) {
      delete result[k];
    }
  }
  return result;
}

function Page(props: Props) {
  const user = useCurrentUser();
  const intl = useIntl();
  const { course, price, coupon, seller } = props.state;
  const [allowRepurchase, setAllowRepurchase] = useState(false);
  const [giftState, setGiftState] = useGiftState(course);
  const isGivingGift = giftState?.email;
  const isFree = !!price && pmtmath.isFree({ price, coupon });

  if (!course || !price) {
    return <ErrorPage title={intl('404 | Not Found')}></ErrorPage>;
  }

  const setCoupon = (value: typeof coupon) => {
    if (value) {
      router.rewrite(setUrlSearchParam('couponId', value.id));
    }
    props.setState((s) => ({ ...s, coupon: value }));
  };

  const purchaseCourse: PurchaseFn = async (opts) =>
    rpx.courseCheckout.purchase({
      ...opts,
      courseId: course.id,
      courseTitle: course.title,
      priceId: price.id,
      couponId: coupon?.id,
      giftRecipientEmail: giftState?.email,
      address: opts?.address ? removeNullProps(opts.address) : undefined,
    });

  const onPurchaseComplete = () => router.redirectTo(`/courses/${course.id}/provision`);

  const giftForm = course.isProduct ? null : (
    <GiftForm
      course={course}
      price={price}
      state={giftState}
      setState={setGiftState}
      previousGifts={props.data.previousGiftEmails}
    />
  );
  if (course.status !== 'published') {
    return (
      <CourseCheckoutErrorPage course={course} title={intl('Signups closed')}>
        {intl('This course is not currently accepting signups.')}
      </CourseCheckoutErrorPage>
    );
  }

  if (props.data.invalidPaypal) {
    return (
      <CourseCheckoutErrorPage course={course} title={intl('PayPal information required')}>
        {intl('This course is not yet configured to accept PayPal payments.')}
      </CourseCheckoutErrorPage>
    );
  }

  if (props.data.isFull) {
    return (
      <CourseCheckoutErrorPage course={course} title={intl('This course is full.')}>
        {intl('This course is no longer available because it is full.')}
      </CourseCheckoutErrorPage>
    );
  }

  if (price.availability !== 'available') {
    return (
      <CourseCheckoutErrorPage
        course={course}
        title={
          price.availability === 'notstarted'
            ? intl('This price is not available yet.')
            : intl('This price is no longer available.')
        }
      >
        {course.guide &&
          fmt.availability({
            item: price,
            intl,
          })}
      </CourseCheckoutErrorPage>
    );
  }

  if (giftState?.isOpen) {
    return (
      <CourseCheckoutPage course={course} price={price} coupon={coupon} setCoupon={setCoupon}>
        {giftForm}
      </CourseCheckoutPage>
    );
  }

  if (!user) {
    return (
      <CourseCheckoutPage
        course={course}
        price={price}
        coupon={coupon}
        setCoupon={setCoupon}
        showHeader={false}
      >
        <AuthForm
          subtitle={price.name}
          giftingToEmail={giftState?.email}
          onSubmit={async (user) => {
            // Reset the gift form if the user is the gift recipient
            if (user && user.email === giftState?.email) {
              setGiftState(undefined);
            }
            router.goto(
              location.pathname +
                setUrlSearchParam('auto', pmtmath.isFree({ price, coupon }).toString()),
            );
          }}
        />
        {giftForm}
      </CourseCheckoutPage>
    );
  }

  if (!isGivingGift && !allowRepurchase && props.data.isEnrolledToCourse) {
    return (
      <CourseCheckoutPage course={course} price={price} coupon={coupon} setCoupon={setCoupon}>
        <RepurchasingForm course={course} onAllowRepurchase={() => setAllowRepurchase(true)} />
        {giftForm}
      </CourseCheckoutPage>
    );
  }

  return (
    <CourseCheckoutPage
      course={course}
      price={price}
      coupon={coupon}
      setCoupon={setCoupon}
      title={isFree ? intl('Checkout') : intl('Payment Method')}
    >
      <PurchaseForm
        collectAddress={seller.collectAddress}
        autoSubmit={props.route.params.auto === 'true'}
        price={price}
        coupon={coupon}
        beginPurchase={purchaseCourse}
        onPurchaseComplete={onPurchaseComplete}
        isGift={!!giftState?.email}
        footer={price.paymentType === 'paid' && giftForm}
      />
    </CourseCheckoutPage>
  );
}

router.add({
  isPublic: true,
  load,
  url: urls.coursePrices.productCheckoutRoute,
  render: Page,
});

router.add({
  isPublic: true,
  load,
  url: urls.coursePrices.checkoutRoute,
  render: Page,
});
